// Runs once to get url params and save them in sessionStorage
import sha256 from 'crypto-js/sha256';

const API_URL = "https://ifmx9cgia9.execute-api.us-west-2.amazonaws.com/prod/fb-conversion"; 

export const trackLead = async (email, phone) => {
    try {
      const hashedEmail = email ? sha256(email).toString() : null;
      const hashedPhone = phone ? sha256(phone).toString() : null;
  
      const userData = {
        em: hashedEmail,
        ph: hashedPhone
      };
  
      await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_name: 'Lead',
          event_id: `lead_${Date.now()}`,
          user_data: userData
        })
      });
  
      console.log('Evento Lead enviado a Facebook.');
    } catch (error) {
      console.error('Error enviando evento Lead:', error);
    }
  };

const getParameterByName = (e, r) => {
    r || (r = window.location.href), (e = e.replace(/[\[\]]/g, "\\$&"));
    var t = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(r);
    return t ? (t[2] ? decodeURIComponent(t[2].replace(/\+/g, " ")) : "") : null;
}

const getDeviceType = () => {
    return navigator.userAgent.match(/Tablet|iPad|iPod|Android/i) &&
        window.innerWidth <= 1280 &&
        window.innerHeight <= 800 &&
        !navigator.userAgent.match(/Mobile/i)
        ? "Tablet"
        : navigator.userAgent.match(
            /Android|BlackBerry|Tablet|Mobile|iPhone|iPad|iPod|Opera Mini|IEMobile/i
        )
            ? "Mobile"
            : "Computer";
}


export default (ctx, inject) => {
    const currentUrl = new URL(window.location.href);
    const refParam = currentUrl.searchParams.get("ref");
    if(refParam && !window.sessionStorage.getItem("ref")) {
        window.sessionStorage.setItem("ref", refParam);
    }
    if (!window.sessionStorage.getItem("prevsite")) {
        window.sessionStorage.setItem("prevsite", document.referrer || "direct traffic");
    }
    window.sessionStorage.setItem("deviceType", getDeviceType());

    inject('trackingVars', {
        ref(){
            return window.sessionStorage.getItem("ref") || "";
        },
        prevSite(){
            return window.sessionStorage.getItem("prevsite") || "";
        },
        deviceType(){
            return window.sessionStorage.getItem("deviceType") || "";
        }
    })

}